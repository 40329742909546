@import "primeicons/primeicons.css";
.jochberg header {
  background-color: green;
}

.sport-alpin header {
  background-color: blue;
}

body,
html {
  overflow-x: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA !important;
  font-size: 1.11rem;
  color: #747A85 !important;
}
body .mg-r-12,
html .mg-r-12 {
  margin-right: 12px !important;
}
body .column,
html .column {
  width: 50%;
  float: left;
  column-gap: 100px;
  color: white;
}
body .dropdown-menu,
html .dropdown-menu {
  width: 100vw;
  /* Setzt die Breite auf 100% der Viewport-Breite */
  left: 0;
  right: 0;
}
body .center,
html .center {
  text-align: center;
}
body .center,
html .center {
  text-align: center;
}
body .cmsHeadline,
html .cmsHeadline {
  color: rgb(197, 181, 141);
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
body .p-sidebar-content,
html .p-sidebar-content {
  padding: 0px !important;
  padding-top: 12px !important;
}
body header .btn,
html header .btn {
  background-color: transparent !important;
  border: unset !important;
  z-index: 9999;
}
body header .carousel-inner,
html header .carousel-inner {
  z-index: 1;
}
body header .text-center,
html header .text-center {
  text-align: center;
  color: white;
}
body .headerImage,
html .headerImage {
  margin-bottom: 5rem !important;
}
body .headerImage img,
html .headerImage img {
  width: 100%;
  height: auto;
  transform: translateY(5rem);
}
body .modal-header,
html .modal-header {
  color: #2e6987;
}
body .modal-body,
html .modal-body {
  color: grey;
}

.p-carousel-next {
  right: 0 !important;
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  z-index: 100 !important;
}

.p-carousel-prev {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  z-index: 100 !important;
}

.textcontainer p {
  margin: 24px !important;
}

.button-online-buchen {
  border: none;
  color: white;
  font-weight: bold;
  background-color: #c5b58d;
  font-family: sans-serif;
  padding: 12px;
}

.button-online-buchen:hover {
  color: #fff;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  display: none !important;
}

.p-component-overlay.p-sidebar-mask.p-component-overlay-enter.ng-tns-c1699961408-0 {
  z-index: 1 !important;
}

.ng-trigger.ng-trigger-panelState.ng-tns-c1699961408-0.p-sidebar.p-sidebar-active.p-sidebar-right.ng-star-inserted {
  z-index: 10000 !important;
}

.myMenuToggle {
  z-index: 100000 !important;
}

.p-sidebar-content {
  padding: 12px !important;
}

.inliner {
  display: inline-block;
  margin: 12px;
}

.container {
  max-width: fit-content !important;
}

@media (max-width: 576px) {
  h1, .headlines-h1, .headlines-p, .responsive-container {
    margin-left: 1rem;
    margin-right: 1rem;
    width: unset !important;
    padding-right: unset !important;
  }
  .sidebuttons-visible {
    opacity: 0.2 !important;
  }
}