@import 'css/jochberg.scss';
@import 'css/sport-alpin.scss';
@import "primeicons/primeicons.css";

//header styling
$header-vertical-lines-color: rgba(0, 0, 0, 0.2);
$header-vertical-lines-width: 4px;
$header-button-family: 'Nunito Sans', sans-serif;
$header-button-text-size: 18px;

$header-burger-distance: 2.25rem;
$header-burger-heigh: 26px;
$header-burger-width: 29px;
$header-burger-top: 3.25rem;

//site-wide margin and padding

$mg36: 36px;
$mg24: 24px;
$pd36: 36px;
$pd24: 24px;
$ci-color: rgb(197, 181, 141);
$ci2-color: #9da1a8;
$ci3-color: rgb(217, 201, 161);
$font-color: #747A85;
$backgroundColor: #FAFAFA;

//sidebar style
$sidebar-padding: 12px;
$sidebar-margin: 0px;

//picture grid component
$picture-margin-container: 4px;
$picture-padding-container: 0px;
$picture-grid-background-color: rgba(217, 201, 161, 0.4);
$picture-grid-height: 600px;

//textbox component
$textbox-title-font-size: 20px;
$textbox-title-font-weight: 400;
$textbox-margin: 0px;
$textbox-padding: 24px;
$textbox-column-max-width: 450px;

//location sidebuttons
$sidebuttons-height: 3.2rem;
$sidebuttons-padding: 0.5rem;


//Verleih
$cartmargin: 12px;
$picturemargin-left: 12px;
$picturemargin-right: 36px;
$checkboxmargin: 24px;
body,
html {
  overflow-x: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
  background-color: $backgroundColor !important;
  font-size: 1.11rem;
  color: $font-color !important;
  
  .mg-r-12 {
    margin-right: 12px !important;
  }
  .column {
    width: 50%;
    float: left;
    column-gap: 100px;
    color: white;

  }



  .dropdown-menu {
    width: 100vw;
    /* Setzt die Breite auf 100% der Viewport-Breite */
    left: 0;
    right: 0;
  }


  .center {
    text-align: center;
  }

  .center {
    text-align: center;
  }

  .cmsHeadline {
    color: $ci-color;
    display: block;
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
  }



.p-sidebar-content {
  padding: 0px !important;
  padding-top: 12px !important;
}
  header {
    .btn {
      background-color: transparent !important;
      border: unset !important;
      z-index: 9999;
    }

    .carousel-inner {
      z-index: 1;

    }

    .text-center {
      text-align: center;
      color: white;
    }

  }

  .headerImage {
    margin-bottom: 5rem !important;
    & img {
      width: 100%;
      height: auto;
      transform: translateY(5rem);
    }
  }

  .modal-header {

    color: #2e6987;


  }

  .modal-body {
    color: grey;


  }

}

.p-carousel-next {
  right: 0 !important;
  position: absolute !important;
  background-color: rgba($color: #FFF, $alpha: .4) !important;
  z-index: 100 !important;
}

.p-carousel-prev {
  position: absolute !important;
  background-color: rgba($color: #FFF, $alpha: .4) !important;
  z-index: 100 !important;

}

// werbecomponent textcontainer margin
.textcontainer p {
  margin: 24px !important;
}

//default online buchen button style
.button-online-buchen {
  border: none;
  color: white;
  font-weight: bold;
  background-color: #c5b58d;
  font-family: sans-serif;
  padding: 12px;
}

.button-online-buchen:hover {
  color: #fff;
}

//delete default close button from p-sidebar
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  display: none !important;
}

//ensure burger menu is visible without overlaying shadow
.p-component-overlay.p-sidebar-mask.p-component-overlay-enter.ng-tns-c1699961408-0 {
  z-index: 1 !important;
}

//ensute sidebar is over header
.ng-trigger.ng-trigger-panelState.ng-tns-c1699961408-0.p-sidebar.p-sidebar-active.p-sidebar-right.ng-star-inserted {
  z-index: 10000 !important;
}

.myMenuToggle {
  z-index: 100000 !important;
}

.p-sidebar-content {
  padding: $sidebar-padding !important;
}

.inliner {
  display: inline-block;
  margin: 12px;
}

.container {
    max-width: fit-content !important;
}


  

@media (max-width: 576px) {
  h1, .headlines-h1, .headlines-p, .responsive-container {
    margin-left: 1rem;
    margin-right: 1rem;
    width: unset !important;
    padding-right: unset !important;
  }
  .sidebuttons-visible {
    opacity: .2 !important;
  }
}

